import React from "react";
import { twMerge } from "tailwind-merge";

import { LoadingCircle } from "../../assets/icons";

type ButtonProps = {
  children: React.ReactNode;
  loading?: boolean;
  className?: string;
  variant?: "solid" | "outline" | "gradient";
  isExternal?: boolean;
  small?: boolean;
} & React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
> &
  React.DetailedHTMLProps<
    React.AnchorHTMLAttributes<HTMLAnchorElement>,
    HTMLAnchorElement
  >;

const Button = ({
  loading = false,
  children,
  type = "button",
  className = "",
  variant = "solid",
  small = false,
  disabled = false,
  isExternal = false,
  href,
  ...rest
}: ButtonProps) => {
  const variantClasses = {
    solid: "bg-slate-gray text-white",
    outline: "bg-transparent border border-jungle-mist text-jungle-mist",
    gradient: "bg-gradient-to-r from-[#0E9DE5] to-[#26DB8D]",
  };
  return href ? (
    // eslint-disable-next-line react/jsx-no-target-blank
    <a
      href={href}
      target={isExternal ? "_blank" : undefined}
      rel={isExternal ? "noopener noreferrer" : undefined}
      className={twMerge(
        "font-bold rounded-[5px]",
        variantClasses[variant],
        small ? "text-xs leading-4 px-2 py-1" : "px-4 py-2 text-sm leading-5",
        className
      )}
      {...rest}
    >
      {children}
    </a>
  ) : (
    <button
      disabled={disabled || loading}
      type={type}
      className={twMerge(
        "font-bold rounded-[5px] disabled:bg-none disabled:bg-slate-gray disabled:opacity-40",
        variantClasses[variant],
        small ? "text-xs leading-4 px-2 py-1" : "px-4 py-2 text-sm leading-5",
        loading ? "flex items-center justify-center" : "",
        className
      )}
      {...rest}
    >
      {loading && <LoadingCircle />}
      {children}
    </button>
  );
};

export default Button;
