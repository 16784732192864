import { Chain as RainbowChain } from "@rainbow-me/rainbowkit";

import EONIcon from "../assets/icons/eon-icon-on-light.svg";

const MAINCHAIN_ID = "mainchain-testnet";
const TOKENMINT_TESTNET_SIDECHAIN_ID =
  "312d210ab35cc9ccba61efa4816efcd581b349eea0dc9df6c4b1a51b23f32eb4";

const duneTestnetChain: RainbowChain = {
  id: Number(process.env.REACT_APP_DUNE_NETWORK_CHAIN_ID || ""),
  name: String(process.env.REACT_APP_DUNE_NETWORK_NAME || ""),
  network: String(process.env.REACT_APP_DUNE_NETWORK_URL || ""),
  iconUrl: EONIcon,
  nativeCurrency: {
    decimals: 18,
    name: "Horizen",
    symbol: "tZEN",
  },
  rpcUrls: {
    default: {
      http: [String(process.env.REACT_APP_DUNE_NETWORK_URL || "")],
    },
    public: {
      http: [String(process.env.REACT_APP_DUNE_NETWORK_URL || "")],
    },
  },
  testnet: true,
};

const gobiTestnetChain: RainbowChain = {
  id: Number(process.env.REACT_APP_GOBI_NETWORK_CHAIN_ID || ""),
  name: String(process.env.REACT_APP_GOBI_NETWORK_NAME || ""),
  network: String(process.env.REACT_APP_GOBI_NETWORK_URL || ""),
  iconUrl: EONIcon,
  nativeCurrency: {
    decimals: 18,
    name: "Horizen",
    symbol: "tZEN",
  },
  rpcUrls: {
    default: {
      http: [String(process.env.REACT_APP_GOBI_NETWORK_URL || "")],
    },
    public: {
      http: [String(process.env.REACT_APP_GOBI_NETWORK_URL || "")],
    },
  },
  testnet: true,
};

export {
  duneTestnetChain,
  gobiTestnetChain,
  MAINCHAIN_ID,
  TOKENMINT_TESTNET_SIDECHAIN_ID,
};
