import React from "react";
// import { useSelector } from "react-redux";
// import { RootState } from "../../../store";
import { GoogleRecaptchaDisclaimer } from "../../../components/GoogleRecaptcha";
import MainchainSecondaryActions from "./MainchainSecondaryActions";
// import SidechainsSecondaryActions from "./SidechainsSecondaryActions";
// import { mainchainId } from "./FaucetFormBody";

const FaucetFormFooter = () => {
  // const selectedTestnet = useSelector(
  //   (state: RootState) => state.faucetForm.selectedTestnet
  // );
  return (
    <>
      <div className="border-b border-b-cyan-blue" />

      {/* For now, we will be showing just the mainchain secondary actions. */}
      {/* {selectedTestnet === mainchainId ? ( */}
      <MainchainSecondaryActions />
      {/* ) : (
        <SidechainsSecondaryActions />
      )} */}
      <GoogleRecaptchaDisclaimer />
    </>
  );
};

export default FaucetFormFooter;
