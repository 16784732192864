import React from "react";
import {
  URL_GOOGLE_PRIVACY_POLICY,
  URL_GOOGLE_TERMS,
} from "../../routes/externals";
import Link from "../Link";
import Typography from "../Typography";

const GoogleRecaptchaDisclaimer = () => {
  return (
    <Typography.TextXS className="font-bold text-jungle-mist block">
      This site is protected by reCAPTCHA and the Google{" "}
      <Link external href={URL_GOOGLE_PRIVACY_POLICY}>
        Privacy Policy
      </Link>{" "}
      and{" "}
      <Link external href={URL_GOOGLE_TERMS}>
        Terms of Service
      </Link>{" "}
      apply.
    </Typography.TextXS>
  );
};

export default GoogleRecaptchaDisclaimer;
