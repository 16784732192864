import React, { useState } from "react";
import { useSelector } from "react-redux";
import {
  MAINCHAIN_ID,
  TOKENMINT_TESTNET_SIDECHAIN_ID,
} from "../../../constants/chains";
import { RootState } from "../../../store";

const useIsEVMTestnetSelected = () => {
  const [isEVMTestnetSelected, setIsEVMTestnetSelected] = useState(false);

  const selectedTestnet = useSelector(
    (state: RootState) => state.faucetForm.selectedTestnet
  );

  const isMainchainTestnet = selectedTestnet === MAINCHAIN_ID;
  const isTokenMintTestnet = selectedTestnet === TOKENMINT_TESTNET_SIDECHAIN_ID;

  React.useEffect(() => {
    if (isMainchainTestnet || isTokenMintTestnet) {
      setIsEVMTestnetSelected(false);
    } else {
      setIsEVMTestnetSelected(true);
    }
  }, [isMainchainTestnet, isTokenMintTestnet]);

  return isEVMTestnetSelected;
};

export default useIsEVMTestnetSelected;
