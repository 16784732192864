import axios from "axios";
import { URL_SIDECHAINS_ID_API } from "../../../routes/externals";

const getSidechainsInfo = () => {
  return axios.get(`${URL_SIDECHAINS_ID_API}/sidechains`).then((response) => {
    const sidechains = response.data.data.sidechains;
    sidechains.forEach((sidechain: any) => {
      sidechain.value = sidechain.id;
      sidechain.label = sidechain.name;
      delete sidechain.id;
      delete sidechain.name;
    });
    const sidechainsWithPre = sidechains.filter((sidechain: any) =>
      sidechain.label.includes("Pre")
    );
    const sidechainsWithoutPre = sidechains.filter(
      (sidechain: any) => !sidechain.label.includes("Pre")
    );
    const orderedSidechains = sidechainsWithoutPre.concat(sidechainsWithPre);

    return orderedSidechains;
  });
};

export default getSidechainsInfo;
