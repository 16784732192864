import React from "react";
import { twMerge } from "tailwind-merge";

type LinkProps = {
  children: React.ReactNode;
  className?: string;
  external?: boolean;
  variant?: "primary";
} & React.DetailedHTMLProps<
  React.AnchorHTMLAttributes<HTMLAnchorElement>,
  HTMLAnchorElement
>;

const Link = ({
  children,
  className = "",
  variant = "primary",
  external = false,
  ...rest
}: LinkProps) => {
  const variantClasses = {
    primary: "text-foundation-blue",
  };
  return (
    <a
      className={twMerge(variantClasses[variant], className)}
      {...rest}
      target={external ? "_blank" : undefined}
      rel={external ? "noopener noreferrer" : undefined}
    >
      {children}
    </a>
  );
};

export default Link;
