import axios from "axios";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { ethers } from "ethers";
import { URL_FAUCET_BACKEND } from "../../../routes/externals";

const getFinalAddress = (address: string) => {
  const isAnEthereumAddress = ethers.utils.isAddress(address);
  let finalAddress = address;
  if (isAnEthereumAddress) {
    if (finalAddress.startsWith("0x")) {
      finalAddress = finalAddress.substring(2);
    }
    finalAddress = finalAddress.padStart(64, "0");
  }
  return finalAddress;
};

const requestFunds = async (
  address: string,
  token: string,
  network?: string
) => {
  let options: {
    address: string;
    recaptchaResponse: string;
    sidechainID?: string;
  } = {
    address: getFinalAddress(address),
    recaptchaResponse: token,
  };

  const isSidechainTestnet = network && network !== "mainchain-testnet";

  if (isSidechainTestnet) {
    options["sidechainID"] = network;
  }

  const faucetEndpoint = isSidechainTestnet
    ? `${URL_FAUCET_BACKEND}/sidechains-faucet`
    : `${URL_FAUCET_BACKEND}/mainchain-faucet`;

  const { data } = await axios.post(faucetEndpoint, options);
  return data;
};

const getZenAmount = () => {
  return axios
    .get(`${URL_FAUCET_BACKEND}/status`)
    .then((res) => res.data.amount);
};

export const useFaucet = () => {
  const [txid, setTxid] = useState(null);
  const requestFundsMutation = useMutation({
    mutationFn: ({
      address,
      token,
      network,
    }: {
      address: string;
      token: string;
      network?: string;
    }) => requestFunds(address, token, network),
    onSuccess: (data) => {
      setTxid(data.data.txid);
    },
  });

  const [zenAmount, setZenAmount] = useState(null);
  const zenAmountQuery = useQuery({
    queryKey: ["zenAmount"],
    queryFn: () => getZenAmount(),
    onSuccess: (amount) => {
      setZenAmount(amount);
    },
  });

  return {
    isFaucetRequestLoading: requestFundsMutation.isLoading,
    isFaucetRequestError: requestFundsMutation.isError,
    isFaucetRequestSuccess: requestFundsMutation.isSuccess,
    requestFundsToFaucet: requestFundsMutation.mutate,
    txid: txid,
    faucetRequestErrors: requestFundsMutation.error as any,
    resetRequest: requestFundsMutation.reset,
    zenAmount: zenAmount,
    isZenAmountLoading: zenAmountQuery.isLoading,
    isZenAmountError: zenAmountQuery.isError,
  };
};
