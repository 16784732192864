const URL = "https://horizen.matomo.cloud/";
const EVENT_TRIGGER_CATEGORY = "Faucet";
enum EVENT_TRIGGER_ACTIONS {
    ADD_TO_METAMASK = "Add to MetaMask",
    VIEW_BLOCK_EXPLORER = "View Block Explorer",
    CLEAR_ADDRESS = "Clear Address",
    REQUEST_TOKENS_ERROR = "Request Tokens Error",
    REQUEST_TOKENS = "Request Tokens",
}

const MATOMO_CONSTANTS = {
    URL,
    EVENT_TRIGGER_CATEGORY,
    EVENT_TRIGGER_ACTIONS,
}

export { MATOMO_CONSTANTS };
export default MATOMO_CONSTANTS;