import { useQuery } from "@tanstack/react-query";
import { getSidechainsInfo } from "../service";

const useSidechainsApi = () => {
  const {
    data: sidechainsInfo,
    isLoading: isSidechainsInfoLoading,
    error: sidechainsInfoError,
    isError: isSidechainsInfoError,
    isSuccess: isSidechainsInfoSuccess,
  } = useQuery(["sidechains"], {
    queryFn: getSidechainsInfo,
    refetchOnWindowFocus: false,
  });

  return {
    sidechainsInfo,
    isSidechainsInfoLoading,
    sidechainsInfoError,
    isSidechainsInfoError,
    isSidechainsInfoSuccess,
  };
};

export default useSidechainsApi;
