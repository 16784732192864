import { useRef } from "react";
import { CheckCircle, Cross, ExternalLink } from "../../../assets/icons";
import { URL_HORIZEN_MAINCHAIN_TESTNET_EXPLORER } from "../../../routes/externals";

import Link from "../../../components/Link";
import { useDispatch, useSelector } from "react-redux";
import { updateTxid } from "../FaucetFormSlice";
import { getSidechainExplorerUrl } from "../service";
import { RootState } from "../../../store";

const SuccessRequestToast = () => {
  const { txid, selectedTestnet, address } = useSelector(
    (state: RootState) => state.faucetForm
  );

  const ref = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();

  const truncate = (str: string) => {
    return str.slice(0, 8) + "..." + str.slice(-8);
  };

  const sidechainExplorer = getSidechainExplorerUrl(selectedTestnet);

  if (txid) {
    return (
      <div>
        <div
          ref={ref}
          className="flex space-x-4 bg-dark-blue-magenta max-w-[600px] mx-auto p-4 rounded-[5px] border-cyan-blue border relative"
        >
          <Cross
            onClick={() => {
              dispatch(updateTxid(null));
              ref.current?.remove();
            }}
            className="cursor-pointer absolute top-4 right-4"
          />
          <CheckCircle />
          <div className="text-sm leading-5 space-y-1">
            <span className="font-semibold block">Request Submitted</span>
            <span className="block text-jungle-mist">
              Transaction ID: {truncate(txid)}
            </span>
            <Link
              href={`${URL_HORIZEN_MAINCHAIN_TESTNET_EXPLORER}/tx/${txid}`}
              external
              className="font-semibold flex items-center"
            >
              View in Mainchain Explorer
              <ExternalLink />
            </Link>
            {sidechainExplorer && (
              <div>
                <span>
                  The funds will appear on the sidechain after 7 confirmations
                  on the Horizen mainchain.
                </span>
                <Link
                  href={`${sidechainExplorer}/address/${address}`}
                  external
                  className="font-semibold flex items-center mt-1"
                >
                  View in Sidechain Explorer
                  <ExternalLink />
                </Link>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }

  return null;
};

export default SuccessRequestToast;
