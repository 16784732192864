import H1 from "./H1";
import TextSM from "./TextSM";
import TextXS from "./TextXS";

const Typography = {
  H1,
  TextSM,
  TextXS,
};

export default Typography;
