import React from "react";

const ExternalLink = () => {
  return (
    <svg
      className="inline ml-1"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.66602 11.3333L11.3327 4.66663M11.3327 4.66663H4.66602M11.3327 4.66663V11.3333"
        stroke="#0E9DE5"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ExternalLink;
