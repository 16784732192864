import { useMatomo } from "@jonkoops/matomo-tracker-react";
import { useLayoutEffect } from "react";
import HorizenLogo from "../assets/logos/HorizenLogo";
import { ConnectWalletModal } from "../components/ConnectWalletModal";
import styles from "./Layout.module.css";

type LayoutProps = {
  children?: React.ReactNode;
};

const Layout = ({ children }: LayoutProps) => {
  const { trackPageView } = useMatomo();

  useLayoutEffect(() => {
    // Note for when we have a router
    // TODO: call trackPageView on route change
    trackPageView({
      documentTitle: document.domain + "/" + document.title,
    });
  }, [trackPageView]);

  // TODO: uncomment when we have wallet connection logic
  // const onConnectWallet = () => {
  //   alert("Connect Wallet");
  //   trackEvent({
  //     category: "Faucet",
  //     action: "Connect Wallet",
  //   });
  // };

  return (
    <div
      className={`${styles.gradientBg} min-h-screen text-white font-proxima`}
    >
      <div className="container mx-auto px-2 sm:px-4 pb-9">
        <div className="flex justify-between items-center py-9">
          <HorizenLogo />
          {/* <Button variant="gradient" onClick={onConnectWallet}>
            Connect Wallet
          </Button> */}
          <ConnectWalletModal />
        </div>
        <div>{children}</div>
      </div>
    </div>
  );
};

export default Layout;
