import {
  GOBI_EXPLORER_URL,
  GOBI_SIDECHAIN_ID,
  PRE_GOBI_EXPLORER_URL,
  PRE_GOBI_SIDECHAIN_ID,
} from "./constants";

const sidechainExplorerUrls = new Map<string, string>();
sidechainExplorerUrls.set(PRE_GOBI_SIDECHAIN_ID, PRE_GOBI_EXPLORER_URL);
sidechainExplorerUrls.set(GOBI_SIDECHAIN_ID, GOBI_EXPLORER_URL);

const getSidechainExplorerUrl = (sidechainId: string) => {
  return sidechainExplorerUrls.get(sidechainId);
};

export default getSidechainExplorerUrl;
