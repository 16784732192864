import React from "react";

import { twMerge } from "tailwind-merge";

type LabelProps = {
  children: React.ReactNode;
  className?: string;
};

const Label = ({ children, className = "" }: LabelProps) => {
  return (
    <label
      className={twMerge(
        "text-jungle-mist font-bold text-sm leading-5",
        className
      )}
    >
      {children}
    </label>
  );
};

export default Label;
