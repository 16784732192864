import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

export interface FaucetFormState {
  selectedTestnet: string;
  txid: string | null;
  address: string;
}

const initialState: FaucetFormState = {
  selectedTestnet: "",
  txid: null,
  address: "",
};

export const FaucetFormSlice = createSlice({
  name: "selectedTestnet",
  initialState,
  reducers: {
    updateSelectedTesnet: (state, action: PayloadAction<string>) => {
      state.selectedTestnet = action.payload;
    },
    updateTxid: (state, action: PayloadAction<string | null>) => {
      state.txid = action.payload;
    },
    updateAddress: (state, action: PayloadAction<string>) => {
      state.address = action.payload;
    },
  },
});

export const { updateSelectedTesnet, updateTxid, updateAddress } =
  FaucetFormSlice.actions;

export default FaucetFormSlice.reducer;
