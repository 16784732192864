import React from "react";
import { twMerge } from "tailwind-merge";

import { TypographySpanProps } from "./TextXS";

type TextSMProps = {
  children: React.ReactNode;
  className?: string;
} & TypographySpanProps;

const TextSM = ({ children, className = "", ...rest }: TextSMProps) => {
  return (
    <span className={twMerge("text-sm leading-5", className)} {...rest}>
      {children}
    </span>
  );
};

export default TextSM;
