import React from "react";
import HorizenLogoSmall from "../../../assets/logos/HorizenLogoSmall";
import Typography from "../../../components/Typography";
import { useFaucet } from "../hooks/useFaucet";
import { renderZenAmount } from "./FaucetFormBody";

const FaucetFormHeader = () => {
  const { zenAmount, isZenAmountLoading } = useFaucet();

  return (
    <>
      <div className="flex items-center">
        <HorizenLogoSmall className="mr-2" />
        <Typography.H1>Horizen Testnet ZEN Faucet</Typography.H1>
      </div>

      <div className="bg-chinese-black px-[14px] py-[10px] rounded-[5px]">
        <Typography.TextSM className="text-jungle-mist">
          <>
            This faucet transfers testnet ZEN (TZEN) on Horizen testnet chains.
            TZEN are for testnet use only and cannot be redeemed for real funds.
            Withdrawals are limited to one request per 24 hours and the amount
            received is {renderZenAmount(isZenAmountLoading, zenAmount)} TZEN.
          </>
        </Typography.TextSM>
      </div>
    </>
  );
};

export default FaucetFormHeader;
