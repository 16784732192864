import React from "react";
import { twMerge } from "tailwind-merge";

export type TypographySpanProps = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLSpanElement>,
  HTMLSpanElement
>;

type TextXSProps = {
  children: React.ReactNode;
  className?: string;
} & TypographySpanProps;

const TextXS = ({ children, className = "", ...rest }: TextXSProps) => {
  return (
    <span className={twMerge("text-xs leading-[18px]", className)} {...rest}>
      {children}
    </span>
  );
};

export default TextXS;
