type HorizenLogoSmallProps = {
  className?: string;
};

const HorizenLogoSmall = ({ className = "" }: HorizenLogoSmallProps) => {
  return (
    <svg
      className={className}
      width="29"
      height="28"
      viewBox="0 0 29 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M25.6459 5.854L23.3408 9.39911C25.9069 14.4052 23.8844 20.5286 18.7958 23.0639C15.8817 24.5249 12.4241 24.5249 9.51004 23.0639L5.92188 25.3629C12.2501 29.8748 21.1009 28.4568 25.6459 22.183C29.2123 17.3058 29.2123 10.7312 25.6459 5.854Z"
        fill="url(#paint0_radial_510_6791)"
      />
      <path
        d="M14.1628 20.9797C17.5988 20.9797 20.5345 18.5733 21.1434 15.2431C16.6637 14.2977 11.9882 15.2431 8.26953 17.8858C9.57432 19.8195 11.7925 20.9797 14.1628 20.9797Z"
        fill="url(#paint1_radial_510_6791)"
      />
      <path
        d="M17.6639 13.6102C18.8817 13.6102 20.0778 13.7391 21.2738 13.997C21.2086 10.1296 17.9684 7.03566 14.054 7.10012C10.2267 7.16457 7.13866 10.2155 7.07342 13.997C7.07342 14.3192 7.09517 14.62 7.13866 14.9423C6.16007 15.6514 5.26847 16.4678 4.46385 17.3917C2.57191 12.0848 5.39894 6.28367 10.7486 4.41443C13.3799 3.49055 16.2939 3.68392 18.7948 4.93008L22.3829 2.63113C16.0547 -1.88083 7.2039 -0.441301 2.65889 5.81098C-0.92927 10.7527 -0.885777 17.4132 2.78937 22.2904C3.46351 21.1087 4.26813 19.9914 5.20323 19.0031C5.20323 19.0031 6.595 17.5636 7.66057 16.8331C10.5529 14.7275 14.054 13.6102 17.6639 13.6102Z"
        fill="white"
      />
      <defs>
        <radialGradient
          id="paint0_radial_510_6791"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(25.6568 5.83682) scale(23.8015 23.5159)"
        >
          <stop offset="0.1" stopColor="#26DB8D" />
          <stop offset="0.89" stopColor="#0E9DE5" />
        </radialGradient>
        <radialGradient
          id="paint1_radial_510_6791"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(25.6558 5.83673) scale(23.8015 23.5159)"
        >
          <stop offset="0.1" stopColor="#26DB8D" />
          <stop offset="0.89" stopColor="#0E9DE5" />
        </radialGradient>
      </defs>
    </svg>
  );
};

export default HorizenLogoSmall;
