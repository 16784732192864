import { ConnectButton } from "@rainbow-me/rainbowkit";
import useIsEVMTestnetSelected from "../../features/HorizenTestTokenFaucet/hooks/useIsEVMTestnetSelected";

const ConnectWalletModal = () => {
  const isEVMTestnetSelected = useIsEVMTestnetSelected();

  if (!isEVMTestnetSelected) return null;

  return <ConnectButton showBalance={false} />;
};

export default ConnectWalletModal;
