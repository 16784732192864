type HorizenLogoProps = {
  className?: string;
};

const HorizenLogo = ({ className = "" }: HorizenLogoProps) => {
  return (
    <svg
      className={className}
      width="157"
      height="28"
      viewBox="0 0 157 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.721 12.3642H3.47943V4.69385H0V23.2788H3.47943V15.544H10.721V23.2788H14.2222V4.69385H10.721V12.3642Z"
        fill="white"
      />
      <path
        d="M68.2393 15.3077C69.2397 14.2334 69.8051 12.6865 69.8051 10.9676C69.8051 9.20581 69.2614 7.70182 68.2611 6.60606C67.4782 5.74664 66.0429 4.71533 63.4986 4.71533H57.7793V23.2788H61.2587V17.2199H62.8462L67.413 23.2788H71.4361L66.4126 16.6183C67.1085 16.3175 67.7392 15.8663 68.2393 15.3077ZM66.5866 10.9676C66.5866 11.9345 66.3474 12.6865 65.869 13.2021C65.3688 13.7822 64.5642 14.0615 63.4986 14.0615H61.2805V7.89519H63.4986C64.5859 7.89519 65.3688 8.1745 65.869 8.73312C66.3256 9.22729 66.5866 9.97928 66.5866 10.9676Z"
        fill="white"
      />
      <path
        d="M82.6142 4.69385H79.1348V23.2573H82.6142V4.69385Z"
        fill="white"
      />
      <path
        d="M92.7699 4.69385V8.15302H99.794L91.1172 23.2788H106.035V19.8196H97.3367L106.035 4.69385H92.7699Z"
        fill="white"
      />
      <path
        d="M114.32 23.2788H125.846V20.099H117.8V15.1788H124.432V11.9989H117.8V7.85222H125.846V4.69385H114.32V23.2788Z"
        fill="white"
      />
      <path
        d="M145.614 16.339L136.785 4.69385H134.219V23.2788H137.72V11.6122L146.592 23.2788H149.137V4.69385H145.614V16.339Z"
        fill="white"
      />
      <path
        d="M47.4076 5.854L45.1025 9.39911C47.6686 14.4052 45.6462 20.5286 40.5575 23.0639C37.6435 24.5249 34.1858 24.5249 31.2718 23.0639L27.6836 25.3629C34.0118 29.8748 42.8626 28.4568 47.4076 22.183C50.974 17.3058 50.974 10.7312 47.4076 5.854Z"
        fill="url(#paint0_radial_510_6828)"
      />
      <path
        d="M35.9245 20.9797C39.3605 20.9797 42.2962 18.5733 42.9051 15.2431C38.4254 14.2977 33.7499 15.2431 30.0312 17.8858C31.336 19.8195 33.5542 20.9797 35.9245 20.9797Z"
        fill="url(#paint1_radial_510_6828)"
      />
      <path
        d="M39.4257 13.6102C40.6435 13.6102 41.8395 13.7391 43.0356 13.997C42.9703 10.1296 39.7301 7.03566 35.8157 7.10012C31.9884 7.16457 28.9004 10.2155 28.8351 13.997C28.8351 14.3192 28.8569 14.62 28.9004 14.9423C27.9218 15.6514 27.0302 16.4678 26.2256 17.3917C24.3336 12.0848 27.1607 6.28367 32.5103 4.41443C35.1416 3.49055 38.0556 3.68392 40.5565 4.93008L44.1446 2.63113C37.8164 -1.88083 28.9656 -0.441301 24.4206 5.81098C20.8324 10.7527 20.8759 17.4132 24.5511 22.2904C25.2252 21.1087 26.0298 19.9914 26.9649 19.0031C26.9649 19.0031 28.3567 17.5636 29.4223 16.8331C32.3146 14.7275 35.8157 13.6102 39.4257 13.6102Z"
        fill="white"
      />
      <path
        d="M152.074 7.10023V4.99465H151.312V4.60791H153.27V4.99465H152.509V7.10023H152.074Z"
        fill="white"
      />
      <path
        d="M155.813 7.10023V5.25248L155.052 7.10023H154.856L154.095 5.25248V7.10023H153.66V4.60791H154.291L154.965 6.24081L155.661 4.60791H156.291V7.10023H155.813Z"
        fill="white"
      />
      <defs>
        <radialGradient
          id="paint0_radial_510_6828"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(47.4185 5.83682) scale(23.8015 23.5159)"
        >
          <stop offset="0.1" stopColor="#26DB8D" />
          <stop offset="0.89" stopColor="#0E9DE5" />
        </radialGradient>
        <radialGradient
          id="paint1_radial_510_6828"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(47.4175 5.83673) scale(23.8015 23.5159)"
        >
          <stop offset="0.1" stopColor="#26DB8D" />
          <stop offset="0.89" stopColor="#0E9DE5" />
        </radialGradient>
      </defs>
    </svg>
  );
};

export default HorizenLogo;
