import ReactDOM from "react-dom/client";
import App from "./App";
import { ZenContextProvider } from "@horizenlabs/zen-ui-kit";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <ZenContextProvider defaultTheme="dark">
    <App />
  </ZenContextProvider>
);
