import React from "react";

const LoadingCircle = () => (
  <svg
    width="12"
    height="14"
    className="animate-spin inline w-[10.5px] h-[10.5px] mr-2"
    viewBox="0 0 12 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.28769 3.28769C1.87353 2.70185 2.58971 2.26297 3.37766 2.00695C4.16562 1.75093 5.00298 1.68503 5.82128 1.81464C6.63959 1.94424 7.4156 2.26568 8.08587 2.75266C8.75615 3.23964 9.30165 3.87835 9.67779 4.61655C10.0539 5.35475 10.25 6.1715 10.25 7C10.25 7.82851 10.0539 8.64525 9.67778 9.38345C9.30165 10.1217 8.75615 10.7604 8.08587 11.2473C7.4156 11.7343 6.63958 12.0558 5.82128 12.1854"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default LoadingCircle;
