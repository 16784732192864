const URL_GOOGLE_PRIVACY_POLICY = "https://policies.google.com/privacy";
const URL_GOOGLE_TERMS = "https://policies.google.com/terms";
const URL_HORIZEN_MAINCHAIN_TESTNET_EXPLORER =
  "https://explorer-testnet.horizen.io";
const URL_FAUCET_BACKEND = process.env.REACT_APP_FAUCET_URL || "";
const URL_SIDECHAINS_ID_API = process.env.REACT_APP_SIDECHAINS_API_URL || "";

export {
  URL_GOOGLE_PRIVACY_POLICY,
  URL_GOOGLE_TERMS,
  URL_HORIZEN_MAINCHAIN_TESTNET_EXPLORER,
  URL_FAUCET_BACKEND,
  URL_SIDECHAINS_ID_API,
};
