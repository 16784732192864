import ReactSelect from "react-select";

const Select = (props: any) => {
  const { options, ...rest } = props;
  return (
    <ReactSelect
      styles={{
        control: (styles) => ({
          ...styles,
          backgroundColor: "#0C0C1C",
          height: "40px",
          border: "1px solid #1F293D",
          boxShadow: "none",
          color: "#fff",
          borderRadius: "5px",
          ":hover": {
            border: "1px solid #0E9DE5",
            cursor: "pointer",
            boxShadow: "none",
          },
          ":focus": {
            border: "1px solid #0E9DE5",
            boxShadow: "none",
          },
          ":active": {
            border: "1px solid #0E9DE5",
            boxShadow: "none",
          },
        }),
        singleValue: (styles) => ({
          ...styles,
          color: "#fff",
        }),
        option: (styles, { isFocused }) => ({
          ...styles,
          backgroundColor: isFocused ? "#1F293D" : "#0C0C1C",
          color: "#fff",
          ":hover": {
            backgroundColor: "#1F293D",
            cursor: "pointer",
          },
        }),
        valueContainer: (styles) => ({
          ...styles,
          padding: "0 12px",
        }),
        menuList: (styles) => ({
          ...styles,
          backgroundColor: "#0C0C1C",
          color: "#fff",
        }),
        indicatorSeparator: (styles) => ({
          ...styles,
          display: "none",
        }),
      }}
      options={options}
      {...rest}
    />
  );
};

export default Select;
