import React from "react";
import ReCAPTCHA from "react-google-recaptcha-enterprise";
import { RECAPTCHA_SITE_KEY } from "./constants";

type GoogleRecaptchaProps = {
  recaptchaRef: React.LegacyRef<ReCAPTCHA>;
};

const GoogleRecaptcha = ({ recaptchaRef: ref }: GoogleRecaptchaProps) => {
  return (
    <ReCAPTCHA
      sitekey={RECAPTCHA_SITE_KEY}
      size="invisible"
      theme="dark"
      ref={ref}
    />
  );
};

export default GoogleRecaptcha;
