import React from "react";
import { twMerge } from "tailwind-merge";

export type TypographyHeadingProps = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLHeadingElement>,
  HTMLHeadingElement
>;

const H1: React.FC<TypographyHeadingProps> = ({
  children,
  className = "",
  ...rest
}) => {
  return (
    <h1
      className={twMerge("font-bold text-[30px] leading-[38px]", className)}
      {...rest}
    >
      {children}
    </h1>
  );
};

export default H1;
