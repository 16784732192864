import React from "react";

import Button from "../../Button";
import { TextInput } from "@horizenlabs/zen-ui-kit";

type InputProps = {
  value: string;
  type?: string;
  children?: React.ReactNode;
  className?: string;
  clearable?: boolean;
  onClear?: () => void;
  setInput?: (value: string) => void;
  errors?: string[];
  name?: string;
  placeholder?: string;
} & React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
>;

const Input = ({
  children,
  className = "",
  type,
  clearable,
  onClear,
  setInput,
  value,
  errors,
  name,
  placeholder,
  ...rest
}: InputProps) => {
  const isError = errors && errors.length > 0;
  return (
    <div className="relative">
      <TextInput
        type={type}
        name={name}
        placeholder={placeholder}
        className="w-full pr-16 text-ellipsis"
        onChange={(e) => setInput && setInput(e.target.value)}
        value={value}
      />

      {clearable && (
        <Button
          disabled={value === undefined || value === null || value === ""}
          className="absolute right-3 top-2 text-foundation-blue opacity-100 disabled:opacity-40 bg-opacity-20 bg-foundation-blue font-semibold"
          onClick={onClear}
          small
        >
          Clear
        </Button>
      )}

      {isError && (
        <ul className="mt-1 -mb-3">
          {errors.map((error) => (
            <li className="text-foundation-red text-xs" key={error}>
              {error}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default Input;
